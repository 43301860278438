import { Outlet } from "react-router-dom";
import Team from "../components/Team";
import FirebaseImages from "../components/FirebaseImages";
import TeamsMenu from "../components/TeamsMenu";

const PortlandPigsMens = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="h-full">
        <Team teamName="Portland Pigs - Womens" />
        <div className=" grid justify-center">
          <p>Games Coming Soon</p>
        </div>
        <FirebaseImages url="images/rugby/pdxpigswomens" />
      </div>
      <Outlet />
    </div>
  );
};

export default PortlandPigsMens;
