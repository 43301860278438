import Filter from "../components/Filter";
import { Outlet } from "react-router-dom";
import FirebaseImages from "../components/FirebaseImages";
import Sport from "../components/Sport";

const Gallery = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Sport sport="Gallery" />
      <Filter />
      <div className="h-full">
        <FirebaseImages url="images/gallery/" />
      </div>
      <Outlet />
    </div>
  );
};

export default Gallery;
