import React from "react";
import camera from "../img/cam.gif";

const Loading = () => {
  return (
    <div className="bg-black min-h-screen ">
      <div className=" grid justify-center pt-52">
        <img src={camera} alt="camera" className=" w-72" />
      </div>
    </div>
  );
};

export default Loading;
