import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import axios from "axios";

const AdminTeams = () => {
  //Grab teams from server
  const [teamsData, setTeamsData] = useState([]);
  useEffect(() => {
    axios
      .get("https://sqphotosapi.herokuapp.com/teams/adminteams")
      .then((response) => {
        setTeamsData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Dropdown menu logic
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="mt-5">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
      >
        Select A team
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/*Displays available games to view*/}
        <MenuItem onClick={handleClose} className="flex flex-col text-left">
          {teamsData.map((team, i) => (
            <div className="py-1" key={i}>
              <NavLink to={team.nav} rel="noreferrer">
                <p key={i}>{team.name}</p>
              </NavLink>
            </div>
          ))}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AdminTeams;
