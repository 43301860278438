import Team from "../components/Team";
import PostAlbum from "./PostAlbum";

const LHSMensHoopsPost = () => {
  return (
    <div className=" bg-black">
      <Team teamName="Lincoln High School - Mens" />
      <PostAlbum team="lhsmenshoops" />
    </div>
  );
};

export default LHSMensHoopsPost;
