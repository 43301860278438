import React from "react";
import { NavLink } from "react-router-dom";
import contact from "../img/contact.gif";
const Contact = () => {
  return (
    <>
      <div className="block space-y-4 md:flex md:space-y-0 md:space-x-4">
        <NavLink to="/ContactForm">
          <button className="relative inline-flex items-center justify-center p-0.5 mb-2 sm:mr-2 xs:mr-0  text-sm font-medium rounded-md bg-gradient-to-r from-pink-500 to-violet-500 text-white ">
            <img src={contact} className="sm:w-11 xs:w-8" alt="contact" />
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 text-white rounded-md group-hover:bg-opacity-0">
              Contact
            </span>
          </button>
        </NavLink>
      </div>
    </>
  );
};

export default Contact;
