import { motion } from "framer-motion";

const Modal = ({ clickedImg, setClickedImg }) => {
  const handleClick = () => {
    setClickedImg(null);
  };

  return (
    <div className="min-w-screen min-h-screen">
      <div className="flex flex-col backdrop-blur-md items-center justify-center overflow-auto fixed inset-0 z-50 outline-none focus:outline-none ">
        <div className="flex align-middle justify-center w-1/2 h-auto pt-2">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            src={clickedImg}
            alt="bigger"
            onClick={handleClick}
            className=" sm:w-full xs:w-full"
          />
        </div>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, delay: 0.5 }}
          className="xs:text-sm sm:text-md text-center pt-2"
        >
          Click or tap image to close.
        </motion.span>
      </div>
    </div>
  );
};

export default Modal;
