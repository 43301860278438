import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import ComingSoon from "../components/ComingSoon";
import football from "../img/football.gif";
import Sport from "../components/Sport";
import TeamsMenu from "../components/TeamsMenu";

const Football = () => {
  //Nothing ready yet
  const [waiting, setWaiting] = useState(false);
  useEffect(() => {
    setWaiting(true);
  }, []);

  return (
    <>
      {waiting ? (
        <ComingSoon sportName="Football" />
      ) : (
        <div className="min-h-screen bg-black text-white">
          <Sport sport="Football" icon={football} alt="Football" />
          <TeamsMenu />
          <Outlet />
        </div>
      )}
    </>
  );
};

export default Football;
