import React, { useState } from "react";
import axios from "axios";
import img from "../img/admin.gif";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const adminLogin = () => {
    const data = { username: username, password: password };
    axios
      .post("https://sqphotosapi.herokuapp.com/auth/login", data)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          sessionStorage.setItem("accessToken", response.data);
          navigate("/admin");
        }
      });
  };
  return (
    <div className="h-screen bg-black">
      <div className="grid justify-center">
        <img src={img} alt="headshot" className="sm:w-40 xs:w-24" />
        <h1 className="xs:text-xl sm:text-4xl text-white pb-10">
          Admin Portal
        </h1>
        <form className="space-y-2">
          <label className="block mb-2 text-sm font-medium text-white">
            Username
          </label>
          <input
            type="text"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <label className="block mb-2 text-sm font-medium text-white">
            Password
          </label>
          <input
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={adminLogin}
            type="button"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
