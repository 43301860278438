import Team from "../components/Team";
import PostAlbum from "./PostAlbum";

const LHSWomensHoopsPost = () => {
  return (
    <div className=" bg-black">
      <Team teamName="Lincoln High School - Womens" />
      <PostAlbum team="lhswomenshoops" />
    </div>
  );
};

export default LHSWomensHoopsPost;
