import React from "react";

const Sport = (props) => {
  return (
    <div className="text-center pt-5 justify-center grid place-items-center">
      <h1 className="mb-2 mt-10 text-4xl font-extrabold leading-none tracking-tight  md:text-5xl lg:text-6xl">
        {props.sport}
      </h1>
      <img
        src={props.icon}
        alt=""
        className="mb-4 w-44 justify-center grid place-items-center"
      />
    </div>
  );
};

export default Sport;
