import React from "react";
import Contact from "../components/Contact";

const Usage = () => {
  return (
    <div className="min-h-screen bg-black text-white text-center">
      <div className="min-w-full grid place-items-center pt-10">
        <p class="text-5xl font-extrabold pb-10">Usage Policy</p>
        <p class="text-3xl font-normal pb-10">
          For any and all forms of media:
        </p>
        <p className="w-1/2 pb-10">
          <span className="underline">
            By using any photo and/or video, you agree to the following terms
            and conditions:
          </span>
          <br></br>
          <ul className="text-left">
            <li className=" list-disc">
              Personal Usage: You are welcome to use any photo or video for
              personal usage such as social media profiles, personal websites,
              and similar applications.
            </li>
            <li className=" list-disc">
              Reselling: You are not permitted to resell any of photo or video
              on any platform without the express consent of Sarah Quist.
            </li>
            <li className=" list-disc">
              Commercial and Publishing Usage: If you wish to use any photo or
              video for commercial or publishing purposes, you must obtain
              written consent from Sarah Quist.
            </li>
            <li className=" list-disc">
              Altering Images: You are not permitted to alter or change any
              photo or video in any way, unless you have obtained written
              consent from Sarah Quist. This does not include changes such as:
              the image's aspect ratio, cropping, and/or simple color changes
              like black and white.
            </li>
            <br></br>
            Note: The above policy is subject to change at any time and without
            notice. If you have any questions about the usage policy or if you
            would like to request permission to use any photo or video
            commercially, please submit an inquiry.
          </ul>
        </p>
        <Contact />
      </div>
    </div>
  );
};

export default Usage;
