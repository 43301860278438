import { Outlet } from "react-router-dom";
import Team from "../components/Team";
import FirebaseImages from "../components/FirebaseImages";
import AlbumsMenu from "../components/AlbumsMenu";

const LHSHoopsWomens = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="h-full">
        <Team teamName="Lincoln High School - Womens" />
        <AlbumsMenu album="lhswomenshoops" />
        <FirebaseImages url="images/basketball/high school/LHS Womens" />
      </div>
      <Outlet />
    </div>
  );
};

export default LHSHoopsWomens;
