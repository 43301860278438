import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const AlbumsMenu = (props) => {
  //Dropdown menu logic
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Grab game links from local server
  const [gamesData, setGamesData] = useState([]);
  useEffect(() => {
    axios
      .get(`https://sqphotosapi.herokuapp.com/albums/${props.album}`)
      .then((response) => {
        setGamesData(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className=" grid justify-center">
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMenu}
        className="text-2xl"
      >
        Find a game
      </Button>
      <Menu
        variant="outlined"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/*Displays available games to view*/}
        <MenuItem onClick={handleClose} className="flex flex-col text-left">
          {gamesData.map((game, i) => (
            <div className="py-1">
              <a key={i} href={game.url} target="_blank" rel="noreferrer">
                <p key={i}>
                  {game.name} {game.date}
                </p>
              </a>
            </div>
          ))}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AlbumsMenu;
