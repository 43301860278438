import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import basketball from "../img/basketball.gif";

import Sport from "../components/Sport";
import TeamsMenu from "../components/TeamsMenu";

const Basketball = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Sport sport="Basketball" icon={basketball} alt="Basketball" />
      <TeamsMenu sportsTeam="basketballteams" />
      <Outlet />
    </div>
  );
};

export default Basketball;
