import React from "react";
import { NavLink } from "react-router-dom";
import soccer from "../img/soccer.gif";
import basketball from "../img/basketball.gif";
import baseball from "../img/baseball.gif";
import football from "../img/football.gif";
import other from "../img/other.gif";

const Filter = () => {
  return (
    <div className=" flex flex-col place-items-center font-medium text-center text-white ">
      <p className="sm:text-2xl xs:text-xl">Select A Category</p>
      <ul className="sm:inline-flex xs:flex sm:flex-row xs:flex-col text-center text-lg md:text-md xs:text-xs">
        <li className="xs:m-0 sm:m-2 md:m-4">
          <NavLink
            to="/football"
            className=" p-4 hover:text-pewter-blue justify-center grid place-items-center"
          >
            <img src={football} className="xs:w-16 md:w-16" alt="emoji" />
            <p className="sm:text-lg xs:text-2xl">Football</p>
          </NavLink>
        </li>
        <li className="xs:m-0 sm:m-2 md:m-4">
          <NavLink
            to="/basketball"
            className="p-4 hover:text-pewter-blue justify-center grid place-items-center"
            aria-current="page"
          >
            <img src={basketball} className="xs:w-16 md:w-16" alt="emoji" />
            <p className="sm:text-lg xs:text-2xl">Basketball</p>
          </NavLink>
        </li>
        <li className="xs:m-0 sm:m-2 md:m-4">
          <NavLink
            to="/baseball"
            className=" p-4 hover:text-pewter-blue justify-center grid place-items-center"
          >
            <img src={baseball} className="xs:w-16 md:w-16" alt="emoji" />{" "}
            <p className="sm:text-lg xs:text-2xl">Baseball</p>
          </NavLink>
        </li>
        <li className="xs:m-0 sm:m-2 md:m-4">
          <NavLink
            to="/soccer"
            className=" p-4 hover:text-pewter-blue justify-center grid place-items-center"
          >
            <img src={soccer} className="xs:w-16 md:w-16" alt="emoji" />{" "}
            <p className="sm:text-lg xs:text-2xl">Soccer</p>
          </NavLink>
        </li>
        <li className="xs:m-0 sm:m-2 md:m-4">
          <NavLink
            to="/rugby"
            className=" p-4 hover:text-pewter-blue justify-center grid place-items-center"
          >
            <img src={football} className="xs:w-16 md:w-16" alt="emoji" />
            <p className="sm:text-lg xs:text-2xl">Rugby</p>
          </NavLink>
        </li>
        <li className="xs:m-0 sm:m-2 md:m-4 ">
          <NavLink
            to="/misc"
            className="p-4 hover:text-pewter-blue justify-center grid place-items-center"
          >
            <img src={other} className="xs:w-16 md:w-16" alt="emoji" />
            <p className="sm:text-lg xs:text-2xl">Misc</p>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Filter;
