import { Outlet } from "react-router-dom";
import ComingSoon from "../components/ComingSoon";
import Sport from "../components/Sport";
import misc from "../img/other.gif";
import TeamsMenu from "../components/TeamsMenu";
import { useState, useEffect } from "react";

const Misc = () => {
  //Nothing ready yet
  const [waiting, setWaiting] = useState(false);
  useEffect(() => {
    //When ready, move to false
    setWaiting(true);
  }, []);

  return (
    <>
      {waiting ? (
        <ComingSoon sportName="Other Sports" />
      ) : (
        <div className="min-h-screen bg-black text-white">
          <Sport sport="Misc" icon={misc} alt="Camera" />
          <TeamsMenu />
          <Outlet />
        </div>
      )}
    </>
  );
};

export default Misc;
