import React, { useState } from "react";
import mail from "../img/mail.gif";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/991d2eb0-9c1b-11ed-82c7-3d7607318e65"; // TODO - fill on the later step

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <div className="text-center pt-10 pb-10 min-w-screen min-h-screen place-items-center bg-lack">
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">
          We'll be in touch soon. Please allow some time for me to get back to
          you.
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      <div className="text-center pt-10 pb-10 min-w-screen  place-items-center bg-black text-white">
        <h1 className="mb-4 text-xl font-extrabold leading-none tracking-tight md:text-2xl lg:text-3xl">
          Contact Me!
        </h1>
        <p className="mb-6 text-md font-normal sm:px-16 xl:px-48">
          If you are interested in booking for a sporting event, or any other
          inquiries, please leave a detailed message below!
        </p>
        <p className="mb-6 text-md font-normal sm:px-16 xl:px-48">
          If you are inquirying about usage for a commercial entity (such as new
          publications), or any other usage question, please refer to the Usage
          Policy first.
        </p>
      </div>
      <div className="min-w-screen grid place-items-center bg-black">
        <form
          action={FORM_ENDPOINT}
          onSubmit={handleSubmit}
          method="POST"
          target="_blank"
          className="w-1/2"
        >
          <div className="mb-3 pt-0">
            <input
              type="text"
              placeholder="Your name"
              name="name"
              className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-gray-200 rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              required
            />
          </div>
          <div className="mb-3 pt-0">
            <input
              type="email"
              placeholder="Email"
              name="email"
              className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-gray-200 rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              required
            />
          </div>
          <div className="mb-3 pt-0">
            <textarea
              placeholder="Your message"
              name="message"
              className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-gray-200 rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              required
            />
          </div>
          <div className="mb-3 pt-0">
            <button
              className="bg-transparent border-1 border-white text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              <img src={mail} alt="mail" className=" w-16" />
              <p>Send</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
