import AlbumsMenu from "../components/AlbumsMenu";
import { Outlet } from "react-router-dom";
import Team from "../components/Team";
import FirebaseImages from "../components/FirebaseImages";

const LHSHoopsMens = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="h-full">
        <Team teamName="Lincoln High School - Mens" />
        <AlbumsMenu album="lhsmenshoops" />
        <FirebaseImages url="images/basketball/high school/LHS Mens" />
      </div>
      <Outlet />
    </div>
  );
};

export default LHSHoopsMens;
