import React from "react";

const Team = (props) => {
  return (
    <div className="text-center pt-5 justify-center grid place-items-center">
      <h1 className="mb-10 font-bold leading-none tracking-tight  md:text-2xl lg:text-3xl">
        {props.teamName}
      </h1>
    </div>
  );
};

export default Team;
