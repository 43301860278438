import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import add from "../img/addcloud.gif";

const PostAlbum = (props) => {
  const initialValues = {
    name: "",
    url: "",
    date: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    url: Yup.string().required("Google Photos URL is required"),
    date: Yup.string().required("Date is required"),
  });

  const onSubmit = (data) => {
    axios
      .post(`https://sqphotosapi.herokuapp.com/albums/${props.team}`, data)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          return alert("Success! You have added the album.");
        }
      })
      .catch((err) => {
        return alert("Error:" + err);
      });
  };
  return (
    <div className="grid justify-center">
      <div className=" text-black bg-charcoal border border-gray-200 rounded-lg p-5">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          className="flex flex-row"
        >
          <Form>
            <label className="pl-2 text-white font-black text-xl  text-left ">
              Name:
            </label>
            <ErrorMessage
              name="name"
              component="span"
              className="text-red-600"
            />
            <Field
              autoComplete="off"
              type="text"
              name="name"
              placeholder="Teams (Ex.. Lincoln vs Grant)"
              className="w-full h-11 rounded-md pl-2"
            />

            <label className="pl-2 text-white font-black text-xl  text-left  pt-2">
              Link:
            </label>
            <ErrorMessage
              name="url"
              component="span"
              className="text-red-600"
            />
            <Field
              autoComplete="off"
              name="url"
              placeholder="Google Photos URL"
              className="w-full h-11 rounded-md pl-2"
            />

            <label className="pl-2 text-white font-black text-xl  text-left  pt-2">
              Date:
            </label>
            <ErrorMessage
              name="date"
              component="span"
              className="text-red-600"
            />
            <Field
              autoComplete="off"
              name="date"
              placeholder="In format 0/00/00"
              className="w-full h-11 rounded-md pl-2"
            />

            <button
              type="submit"
              className=" inline-flex items-center justify-center w-1/2 mt-4 text-white bg-gradient-to-r from-pink-500 to-violet-500 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-2 "
            >
              <img src={add} alt="add" className="w-8" />
              <span className="pl-2 font-bold">Add</span>
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PostAlbum;
