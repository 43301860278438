import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import action from "../img/actionshot.jpeg";
import actionTwo from "../img/actionshot2.png";
import { motion } from "framer-motion";
import vid from "../video/index-vid.mp4";
import Filter from "../components/Filter";
import gallery from "../img/gallery.gif";
import Loading from "../components/Loading";

const Index = () => {
  //Force loading animation
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1800); //Wait 1.8s
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="min-h-screen bg-black text-white overflow-x-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.25,
              ease: "easeOut",
            }}
            className="text-center pt-10 pb-10"
          >
            <h1 className="text-5xl font-extrabold pb-2">SQ Sports Media</h1>
            <p className="bg-clip-text text-lg text-transparent bg-gradient-to-r from-pink-500 to-violet-500 pt-5 pb-10">
              Specializing in sports media all up and down the west coast.
            </p>
            <NavLink to="/gallery">
              <button className="inline-flex items-center rounded-lg justify-center px-5 py-4 md:w-72 xs:w-44 transition-all ease-in duration-200 from-pink-500 to-yellow-500 text-white bg-gradient-to-br  font-medium text-mdtext-center mr-2 mb-2">
                Gallery
                <svg
                  className="md:w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                ></svg>
                <img src={gallery} alt="gallery" className="sm:w-11 xs:w-6" />
              </button>
            </NavLink>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.25 }}
            >
              <video
                src={vid}
                autoPlay
                loop
                muted
                playsInline
                className="w-full h-full object-center object-cover"
              />
            </motion.div>
          </motion.div>
          <div className="w-screen">
            <div className="w-full">
              <Filter />
            </div>
          </div>
          <div className="xs:flex xs:flex-col sm:flex-row sm:justify-between xs:justify-center align-middle place-items-center p-10">
            <img
              src={action}
              className="xs:w-4/5 sm:w-1/4 lg:w-1/3 rounded-md h-full mb-10"
              alt="action"
            />
            <img
              src={actionTwo}
              className="xs:w-4/5 xs:mt-10 sm:m-0 sm:w-1/4 lg:w-1/3 rounded-md h-full mb-10"
              alt="action"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
