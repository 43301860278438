import { Outlet } from "react-router-dom";
import rugby from "../img/football.gif";
import Sport from "../components/Sport";
import TeamsMenu from "../components/TeamsMenu";

const Rugby = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Sport sport="Rugby" icon={rugby} alt="Rugby" />
      <TeamsMenu sportsTeam="rugbyteams" />
      <Outlet />
    </div>
  );
};

export default Rugby;
