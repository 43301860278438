import { Outlet } from "react-router-dom";
import ComingSoon from "../components/ComingSoon";
import Sport from "../components/Sport";
import soccer from "../img/soccer.gif";
import TeamsMenu from "../components/TeamsMenu";
import { useState, useEffect } from "react";

const Soccer = () => {
  const [waiting, setWaiting] = useState(false);
  useEffect(() => {
    setWaiting(true);
  }, []);

  return (
    <>
      {waiting ? (
        <ComingSoon sportName="Soccer" />
      ) : (
        <div className="min-h-screen bg-black text-white">
          <Sport sport="Soccer" icon={soccer} alt="Soccer" />
          <TeamsMenu />
          <Outlet />
        </div>
      )}
    </>
  );
};

export default Soccer;
