import { getDownloadURL, listAll, ref } from "firebase/storage";
import React, { useState, useEffect } from "react";
import { storage } from "../utils/firebaseconfig";
import Modal from "../components/Modal";

const FirebaseImages = (props) => {
  //Image modal pop-up
  const [clickedImg, setClickedImg] = useState(null);
  const handleClick = (image) => {
    setClickedImg(image);
  };
  //Grab images from Firebase Storage
  const [image, setImage] = useState([]);
  useEffect(() => {
    const allImages = ref(storage, `${props.url}`);
    listAll(allImages)
      .then((res) => {
        const promises = res.items.map((imageRef) => getDownloadURL(imageRef));
        Promise.all(promises).then((image) => {
          setImage(image);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="gap-2 md:columns-2 xs:columns-1 p-4">
      {/*Gallery Images*/}
      {image.map((image, index) => {
        return (
          <div key={index}>
            <img
              src={image}
              alt="gallery"
              className="w-full p-2 cursor-pointer"
              onClick={() => handleClick(image)}
            />
          </div>
        );
      })}
      {clickedImg && (
        <Modal clickedImg={clickedImg} setClickedImg={setClickedImg} />
      )}
    </div>
  );
};

export default FirebaseImages;
