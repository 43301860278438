import React from "react";
import { motion } from "framer-motion";
import Filter from "./Filter";
import error from "../img/error.gif";

const ComingSoon = (props) => {
  return (
    <div className="min-h-screen bg-black text-white">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.25,
          ease: "easeOut",
        }}
        className="text-center pt-10 pb-10 h-screen grid justify-center place-items-center align-middle"
      >
        <p class="text-5xl font-extrabold pb-2">
          No Photos For {props.sportName} Yet!
        </p>
        <img src={error} alt="error" className=" w-40" />
        <p className="bg-clip-text md:text-2xl xs:text-lg text-transparent bg-gradient-to-r from-pink-500 to-violet-500 pt-5 pb-10">
          Check back another time.
        </p>
        <Filter />
      </motion.div>
    </div>
  );
};

export default ComingSoon;
