//Main
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import React from "react";

//Components
import ComingSoon from "./components/ComingSoon";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

//Layouts
import RootLayout from "./layouts/RootLayout";

//Pages
import Football from "./pages/Football";
import Gallery from "./pages/Gallery";
import Baseball from "./pages/Baseball";
import Basketball from "./pages/Basketball";
import Misc from "./pages/Misc";
import Rugby from "./pages/Rugby";
import Soccer from "./pages/Soccer";
import ContactForm from "./pages/ContactForm";
import Usage from "./pages/Usage";
import Index from "./pages/Index";
import Admin from "./pages/Admin";

//Subpages
import Lhshoopsmens from "./pages/LHSHoopsMens";
import Lhshoopswomens from "./pages/LHSHoopsWomens";
import PortlandPigsMens from "./pages/PortlandPigsMens";
import PortlandPigsWomens from "./pages/PortlandPigsWomens";

//Admin pages
import Login from "./pages/Login";
import LHSMensHoopsPosts from "./admin/LHSMensHoopsPost";
import LHSWomensHoopsPosts from "./admin/LHSWomensHoopsPost";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<RootLayout />}
      errorElement={
        <>
          <Navbar />
          <ComingSoon />
          <Footer />
        </>
      }
    >
      <Route index element={<Index />} />
      <Route path="football" element={<Football />} />
      <Route path="gallery" element={<Gallery />} />
      <Route path="baseball" element={<Baseball />} />
      <Route path="basketball" element={<Basketball />}>
        <Route path="lhshoopsmens" element={<Lhshoopsmens />} />
        <Route path="lhshoopswomens" element={<Lhshoopswomens />} />
      </Route>
      <Route path="soccer" element={<Soccer />} />
      <Route path="misc" element={<Misc />} />
      <Route path="rugby" element={<Rugby />}>
        <Route path="pdxpigsmens" element={<PortlandPigsMens />} />
        <Route path="pdxpigswomens" element={<PortlandPigsWomens />} />
      </Route>
      <Route path="contactForm" element={<ContactForm />} />
      <Route path="usage" element={<Usage />} />
      {/*Admin Routing */}
      <Route path="login" element={<Login />} />
      <Route path="admin" element={<Admin />}>
        <Route path="lhshoopsmenspost" element={<LHSMensHoopsPosts />} />
        <Route path="lhshoopswomenspost" element={<LHSWomensHoopsPosts />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
