import React from "react";
import { NavLink } from "react-router-dom";
import Contact from "./Contact";
import home from "../img/home.gif";
import gallery from "../img/gallery-gradient.gif";
import FindATeam from "./FindATeam";

function Navbar() {
  return (
    <nav className="  bg-black text-white xs:px-6 pt-5">
      <div className="container flex items-center justify-between ">
        <div className="flex flex-row justify-around w-1/6 ">
          <NavLink to="/" className="justify-center grid place-items-center">
            <img src={home} className="md:w-8 xs:w-6" alt="home" />
            <p className="xs:text-transparent md:text-md text-center md:text-white">
              Home
            </p>
          </NavLink>
          <NavLink
            to="/gallery"
            className="justify-center grid place-items-center"
          >
            <img src={gallery} className="md:w-8 xs:w-6" alt="gallery" />
            <p className="xs:text-transparent md:text-md text-center md:text-white ">
              Gallery
            </p>
          </NavLink>
        </div>
        <div className="flex space-x-10">
          <FindATeam />
          <Contact />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
