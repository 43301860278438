import React from "react";
import img from "../img/admin.gif";
import { Outlet } from "react-router-dom";
import AdminTeams from "../admin/AdminTeams";

const Admin = () => {
  return (
    <div className="h-screen bg-black text-white grid">
      <div className="h-1/2 text-center pt-4 grid justify-center place-items-center">
        <img src={img} alt="headshot" className="sm:w-40 xs:w-24" />
        <h1 className="xs:text-xl sm:text-4xl">Admin Portal</h1>
        <AdminTeams />
      </div>
      <Outlet />
    </div>
  );
};
export default Admin;
